#root {
  height: 100%;
}

// COMPONENTS STYLE
.font-24 {
  @include fs-24;
}

.font-20 {
  @include fs-20;
}

.font-18 {
  @include fs-18;
}

.font-16 {
  @include fs-16;
}

.font-14 {
  @include fs-14;
}

.fw-bold {
  font-weight: $font-bold;
}

.fw-med {
  font-weight: $font-medium;
}

.fw-reg {
  font-weight: $font-regular;
}

.fw-semibold {
  font-weight: $font-semibold;
}

.lh-28 {
  line-height: 28px;
}

.lh-30 {
  line-height: 28px;
}


// SPACING
.mb-40 {
  margin-bottom: 40px;
}

.my-40 {
  margin: 40px 0;

  @include media(down, $breakpoint-lg) {
    margin: 20px 0;
  }
}

.my-80 {
  margin: 80px 0;
}

// BACKGROUND COLORS
.bg-orange-light {
  background-color: #fef2eb !important;
}

.bg-green-light {
  background-color: #e9f6e7 !important;
}

.object-cover {
  object-fit: cover;
}

.cursor-pointer {
  cursor: pointer;
}

.action-btn {
  .icon {
    font-size: 24px;
    color: $dark;
    cursor: pointer;
  }
}

.text-decoration-underline {
  text-decoration: underline;
}

.text-decoration-none {
  text-decoration: none;
}

/**
* ----------------------------------------
* Main Wrapper
* ----------------------------------------
*/
.main-wrapper {
  min-height: calc(100% - 124px);
  height: auto;
  margin-bottom: -124px;

  &::after {
    content: "";
    display: block;
    height: 124px;
  }
}

/**
* ----------------------------------------
* User stripe
* ----------------------------------------
*/
.user-strip {
  padding: 4px;
  background: linear-gradient(90.02deg, #236db6 0%, #041f7a 100%);
  box-shadow: 0px 2px 4px rgba($black, 0.12);

  .stripe-content {
    @include fs-16;
    color: $white;
  }
}

/**
  * ----------------------------------------
  * Catalog Menu
  * ----------------------------------------
  */
.catalog-menu {
  display: block;
  background-color: #edeef3;

  .menu-list {
    padding: 22px 0;

    li {
      &:not(:last-child) {
        margin-right: 40px;
      }

      a {
        display: flex;
        align-items: center;
        color: $dark;
        @include fs-18;

        &:hover {
          color: $secondary;
        }
      }

      .icon {
        margin-right: 10px;
        color: $secondary;
        font-size: 24px;
      }
    }
  }
}

/**
  * ----------------------------------------
  * Breadcrumb
  * ----------------------------------------
  */
.breadcrumb {
  background-color: transparent;
  padding: 0;
  margin-bottom: 40px;

  @include media(down, $breakpoint-xl) {
    margin-bottom: 24px;
  }

  @include media(down, $breakpoint-sm) {
    margin-bottom: 15px;
  }

  .breadcrumb-item {
    color: $dark;

    // font-size: 16px;
    // line-height: 30px;
    @include media(down, $breakpoint-xl) {
      padding-left: 0;
    }

    a {
      color: #082982;
      // font-size: 16px;
      // line-height: 30px;
      @extend .font-18;

      &:hover {
        color: $dark-blue;
      }
    }

    +.breadcrumb-item {
      &::before {
        content: "\e911";
        font: normal normal $font-regular 26px/1.3 $icomoon-font-family;
        color: $dark;

        @include media(down, $breakpoint-xxl) {
          line-height: 1.1;
        }

        @include media(down, $breakpoint-xl) {
          padding-right: 0;
        }
      }
    }
  }
}

.gradient-circle {
  display: flex;
  flex: 0 0 42px;
  align-items: center;
  justify-content: center;
  height: 42px;
  width: 42px;
  margin-right: 12px;
  background: radial-gradient(68.76% 89.29% at 50% 10.71%,
      #f1ffff 0%,
      #71ccf5 54%,
      #0a7cb6 100%);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 100%;

  .icon {
    font-size: 22px;

    @include media(down, $breakpoint-xxl) {
      font-size: 20px;
    }
  }
}

/**
  * ----------------------------------------
  * Badge
  * ----------------------------------------
  */
.badge-outline-primary {
  border: 1px solid $light;
  border-radius: 70px;
  @include fs-16;
  padding: 5px 8px;
  font-weight: $font-regular;

  .icon {
    margin-left: 10px;
    font-size: 10px;
    cursor: pointer;

    &:hover {
      color: $danger;
    }
  }
}

/**
  * ----------------------------------------
  * Added by KP
  * ----------------------------------------
  */

.text-between-line {
  display: flex;
  flex-direction: row;
  color: $secondary;
  margin: 12px auto;
  @include fs-14;
  font-weight: $font-regular;

  &:before,
  &:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid $light;
    margin: auto;
  }

  &:before {
    margin-right: 10px;
  }

  &:after {
    margin-left: 10px;
  }
}

.sm-single-select {
  max-height: 40px;
  padding: 0 50px 0 10px;
}

.eqi-place {
  .lable-n-value {

    // margin-bottom: 5px;
    label {
      color: $label-color;
      @include fs-14;
      font-weight: $font-regular;
      margin-right: 5px;
      margin-bottom: 0;
    }

    .label-value {
      color: $dark;
      @include fs-14;
      font-weight: $font-medium;
    }
  }
}

.order-details-layout {
  padding: 20px;
  border: $border-width solid $border-color;
  border-radius: 4px;
  position: relative;

  .lable-n-value {

    .label-value,
    label {
      line-height: 22px;
    }
  }

  .order-info-content {
    .eqi-place {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  .badge {
    padding: 0 6px;
    @include fs-14;
    line-height: 2;
    border-radius: 5px;

    @include media(down, $breakpoint-md) {
      position: absolute;
      right: 14px;
      padding: 2px 8px;
    }
  }
}

.text-danger,
.text-success,
.text-warning,
.td-eqi {
  span {
    color: $gray;
  }
}

.notification-text {
  @include fs-14;
  font-weight: $font-medium;
  color: $dark-blue;
  letter-spacing: 0.1px;
}

.custon-radio-btn {
  span {
    @include fs-20;
    font-weight: $font-regular;
  }
}

.alert-modal {
  .modal-content {
    max-width: 406px;

    h3 {
      @include fs-20;
      font-weight: $font-bold;
    }

    .btn {
      padding: 0.76rem 1rem;
    }

    p {
      @include fs-18;
      font-weight: $font-regular;
    }
  }
}

.input-icon {
  position: absolute;
  font-size: 20px;
  font-family: $icomoon-font-family;
  cursor: pointer;
  @include css3-prefix(transform, translateY(-50%));

  &::before {
    top: 50%;
    left: 12px;
  }

  &::after {
    top: 50%;
    right: 12px;
  }
}

.datepicker-icon {
  .react-datepicker__input-container {
    &::after {
      content: "\e924";
      @extend.input-icon;
    }

    button {
      @extend.clear-search;
      top: 3px;
      right: 24px;
      font-weight: $font-regular !important;

      &::after {
        content: "\e904";
        @extend.input-icon;
        background-color: transparent;
        color: $dark;
        font-size: 10px;
      }
    }
  }
}

/**
  * ----------------------------------------
  * Grid Pagination
  * ----------------------------------------
  */
.grid-pagination {
  padding: 12px 5px;
  @include fs-14;
  font-weight: $font-medium;
  color: $label-color;
  text-transform: capitalize;

  .active,
  option {
    color: $dark;
  }

  select {
    border-color: $border-color;
    margin: 0 6px;

    &:focus-visible {
      outline: 0;
    }

    option {
      &:focus-visible {
        background-color: red;
      }
    }
  }

  ul {
    li {
      margin: 5px;

      a {
        color: $dark;
      }

      &.disabled {
        a {
          color: $label-color;
        }
      }

      &.selected {
        padding: 7px 12px;
        border: 1px solid $dark;
        border-radius: 5px;
      }
    }
  }
}

.alert {
  border: 1px solid #dfdfdf;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.04);

  .alert-heading {
    margin-bottom: 0;
    @include fs-18;
    font-weight: $font-bold;
  }

  p {
    @include fs-16;
    line-height: 18px;

    @include media(down, $breakpoint-xxl) {
      line-height: 18px;
    }
  }

  &.alert-success {
    background-color: #f5fcf9;
    color: $success;
  }

  &.alert-warning {
    background-color: #fef9f4;
    color: $warning;
  }

  &.alert-danger {
    background-color: #fef4f4;
    color: $danger;
  }

  .close {
    top: 20px;
    right: 20px;
    padding: 0;
    opacity: 1;

    &::after {
      content: "\e904";
      font-family: $icomoon-font-family;
      @include fs-14;
      color: $dark;
    }

    span {
      display: none;
    }
  }
}

.alert-popup {
  z-index: 9999;
  position: fixed;
  top: 105px;
  right: 5px;
  max-width: 420px;
  padding: 10px 50px 10px 10px;

  @include media(down, $breakpoint-xxl) {
    max-width: 320px;
  }
}

.custom-table {
  td {
    border: 0;
  }
}

.invalid-feedback {
  display: block;
  margin-top: 6px;

  @include media(down, $breakpoint-sm) {
    @include fs-14
  }
}

.card-body {
  margin-top: 6px;

  @include media(down, $breakpoint-sm) {
    padding: 15px;
  }
}

.loader-wrap {
  background-color: rgba($black, 0.8);

  .loader {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: $white;

    span {
      @include fs-16;
      margin-top: -7px;
    }
  }
}

sup {
  top: 0;
  color: $danger;
  @include fs-16;
}

/* Change the input bg white on selected */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.autocomplete-dropdown-container {
  border-radius: 4px;
  box-shadow: 0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%);
  margin-bottom: 8px;
  margin-top: 8px;
  position: absolute;
  z-index: 99;
  width: 100%;

  @include media(down, $breakpoint-lg) {}

  .autocomplete-custom {
    max-height: 300px;
    overflow-y: auto;
    padding-bottom: 4px;
    padding-top: 4px;
    position: relative;

    .suggestion-item,
    .g-location-loading,
    .suggestion-item--active {
      background: $white;
      color: #151a30;
      @include fs-16;
      padding: 12px;
      width: 100%;

      .google-address {
        span {
          margin-top: 1.5px;
          color: $dark;
        }
      }
    }

    .suggestion-item--active {
      background: #eceef3;
    }
  }
}

// qty number input
.number-select {
  input {
    height: 34px;
    min-height: 34px;
    max-width: 80px;
    width: 80px;
    padding: 6px 8px;
    // margin: 0 auto;
    border: 1px solid $dark;
    border-radius: 5px;
    box-shadow: none;
    @include media(down, $breakpoint-xxl) {
      max-width: 70px;
      width: 70px;
    }
    @include media(down, $breakpoint-xl) {
      height: 30px;
      min-height: 30px;
    }

    @include media(down, $breakpoint-md) {
      height: 26px;
      min-height: 26px;
      max-width: 72px;
      width: 72px;
    }

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    &[type="number"] {
      -moz-appearance: textfield;
    }

    &::placeholder {
      color: $gray;
    }
  }
}

@media print {
  .hide-print {
    display: none !important;
  }

  .print-row {
    display: flex;

    .print-col-50 {
      width: 50%;
    }
  }

  .custom-nowrap {
    white-space: nowrap;
  }

  .container {
    .title-content {
      margin-top: 40px;
      width: 100%;
      justify-content: center;
    }

  }
}

.pagenotfound {
  margin-top: 50px;
}

.mxw-whd {
  @include media(down, $breakpoint-md) {
    max-width: inherit;
  }

  @include media(down, $breakpoint-xl) {
    max-width: 196px;
  }
}

.min-h-20 {
  min-height: 20px;
  align-items: center;
  display: flex;
}
.ml-1366-6{
  @include media(down, $breakpoint-xxl) {
  margin-left: 6px;
  }
}
.p-1366-10{
  @include media(down, $breakpoint-xxl) {
    padding: 10px !important;
  }
}
@include media(down, $breakpoint-xxl) {
  .px-6-1366{
    padding-right: 6px !important;
    padding-left: 6px !important;
  }
  .px-8-1366{
    padding-right: 8px !important;
    padding-left: 8px !important;
  }
.px-10-1366{
  padding-right: 10px !important;
  padding-left: 10px !important;
}
.flex-wrap-1366{
  flex-wrap: wrap !important;
}
}