/**
* ----------------------------------------
* Product details Table
* ----------------------------------------
*/
.table-responsive {
  @include media(down, $breakpoint-sm) {
    position: relative;
  }
}

.order-details-layout {
  +.table-responsive {
    @include media(down, $breakpoint-lg) {
      position: relative;
    }
  }
}

.custom-table {
  border: 1px solid $border-color;
  border-radius: 4px;

  th,
  td {
    @include fs-14;
    vertical-align: top;

    @include media(down, $breakpoint-md) {
      vertical-align: top;
    }
  }

  thead {
    th {
      padding: 12px;
      background-color: $border-color;
      white-space: nowrap;
      font-weight: $font-bold;
    }
  }

  tbody {
    tr {
      &:hover {
        background-color: rgba(236, 238, 243, 0.5);

        .icon,
        .highlighted-col {
          &:first-child {
            color: $primary;
          }
        }

        .disabled {
          color: $light;
        }

        .highlighted-col {
          &:first-child {
            text-decoration: underline;
          }
        }
      }

      &.out-of-stock {
        td {
          color: $danger;

          .notify-me {
            .btn-link {
              color: $primary;
              @include fs-14;
                vertical-align: unset;
            }
          }

          .number-select {

            .custom_select__single-value,
            .custom_select__input,
            input {
              color: $danger !important;
              border-color: $danger !important;
            }
          }
        }

        .custom_select,
        input {

          &:hover,
          &__control--is-focused,
          &__control {
            border-color: $danger !important;
          }

          &__placeholder {
            color: $danger;
          }

          &__dropdown-indicator {
            &::after {
              color: $danger;
            }
          }
        }
      }
    }

    td {
      padding: 10px 12px;
      font-weight: $font-medium;
    }
  }
}

.product-list-table {
  border: 1px solid $border-color;
  border-radius: 4px;
  overflow: auto;
  height: 268px;

  @include media(down, $breakpoint-tab) {
    max-height: 265px;
    height: 100%;
  }

  thead {
    th {
      z-index: 1;
      position: sticky;
      top: 0;
      border-top: 0px solid transparent;
    }
  }

  .custom-table {

    td {
      vertical-align: middle !important;
    }
  }
}

/**
* ----------------------------------------
* Cart Products Table
* ----------------------------------------
*/
.cart-table {
  border-collapse: separate;
  border-spacing: 0 12px;

  thead {
    th {
      padding-top: 0;
      padding-bottom: 0;
      border: none;
      padding: 0 16px;
      @include fs-16;
      font-weight: $font-bold;
      white-space: nowrap;

      &:first-child {
        padding-left: 0;
      }
    }
  }

  tbody {
    tr {
      border-radius: 5px;
    }

    td {
      border-top: 1px solid $light;
      border-bottom: 1px solid $light;
      padding: 16px;

      // border: none;
      &:first-child {
        border-radius: 5px 0 0 5px;
        border-left: 1px solid $light;
        width: 650px;

        @include media(down, $breakpoint-xxl) {
          width: 535px;
        }

        @include media(down, $breakpoint-xl) {
          width: 620px;
        }

        @include media(down, $breakpoint-lg) {
          width: 480px;
        }

        @include media(down, $breakpoint-md) {
          min-width: 450px;
        }
      }

      &:last-child {
        border-radius: 0 5px 5px 0;
        border-right: 1px solid $light;
      }
    }
  }
}

// Inventory list table
.inventory-table {
  .icon-trash {
    font-size: 20px;
    cursor: pointer;

    &.disabled {
      color: $light;
      cursor: not-allowed;
    }
  }
}
.notify-me {
  .btn-link {
    color: $primary;
    @include fs-14;
      vertical-align: unset;
  }
}